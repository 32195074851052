.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchContainer {
  position: relative;
  width: 75%;
}

.searchInput {
  width: calc(100% - 30px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.filterContainer {
  display: flex;
  align-items: center;
}

.dropdown {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.filterIcon {
  margin-left: 10px;
  cursor: pointer;
}

.bottomSection {
  margin-top: 60px;
}

.noticeHeading {
  padding: 15px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: justify;
  cursor: pointer;
  color: rgb(17, 17, 27);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3); /* Further increased elevation */
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.membersTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.membersTable th,
.membersTable td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
  /* text-align: center; */
}

.membersTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.unitCell {
  font-weight: bold;
  background-color: #f2f2f2;
}

.TableContainer {
  margin: 10px;
}

.TableContainer span {
  display: flex;
  align-items: center;
}

.membersTable a {
  text-decoration: none;
  color: inherit;
}

.textColour {
  color: red;
  font-size: larger;
}
