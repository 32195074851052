.aboutContainer {
  text-align: center;
  margin: auto;
  max-width: 95%; /* Adjust max-width for responsiveness */
}

.aboutContainer h2 {
  margin-bottom: 10px;
  color: #333; /* Dark color for headings */
}

.aboutContainer p {
  text-align: justify; /* Align paragraph text to the left */
  margin-bottom: 15px;
}
.contactDetailsC {
  max-width: 70%; /* Adjust max-width for responsiveness */
  text-align: center;
  margin: 20px auto;
}
.contactDetails {
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.contactColumn {
  flex: 1; /* Each column takes equal space */
  text-align: left;
}

.contactColumn p {
  margin-bottom: 10px;
}

.contactColumn strong {
  font-weight: bold;
  color: #333; /* Dark color for strong tags */
}

.contactColumn a {
  color: #0066cc; /* Blue color for links */
  text-decoration: none;
}

.contactColumn a:hover {
  text-decoration: underline; /* Underline links on hover */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
  max-width: 800px; /* Adjust max-width for responsiveness */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* background-color: #f9f9f9; */
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.formGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
  width: 20%; /* Adjust width of labels column */
}

.input,
.textarea,
.select {
  flex: 1; /* Fill remaining space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea {
  resize: vertical;
  height: 100px;
}

.buttonContainer {
  display: flex;
  justify-content: center; /* Center the button horizontally */
}

.button {
  margin-top: 10px; /* Add margin-top for button spacing */
  padding: 10px 45px;
  background-color: rgb(11, 33, 138);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}
