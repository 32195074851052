.C {
  width: 98%;
  margin: 0 auto;
}

.padadhikari {
  text-align: center;
}

.padadhikariList {
  /* list-style-type: none; */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  flex-wrap: wrap; /* Allow items to wrap if they overflow */
}

.padadhikariList li {
  margin: 10px 30px; /* Adjust margin as needed */
}

.padadhikariList li h2 {
  margin: 0; /* Remove default margin */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  justify-content: center;
  margin-top: 40px;
}

.column {
  flex: 1;
  text-align: center;
  margin: 10px;
  display: flex; /* Align items vertically */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items horizontally */
}

.image {
  width: 100%;
  max-width: 200px;
  height: 14em;
  object-fit: cover;
  border: rgb(11, 33, 128) 1px solid;
}

.name {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px; /* Adjust margin for spacing */
  line-height: 1.2; /* Ensure consistent line height */
  max-height: 2.4em; /* Limit height to two lines */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  white-space: nowrap; /* Prevent text wrapping */
}

.post {
  font-size: 1rem;
  margin-top: 5px;
  line-height: 1.2; /* Ensure consistent line height */
}

.phone {
  font-size: 1rem;
  margin-top: 5px;
  line-height: 1.2; /* Ensure consistent line height */
}

.centerText {
  text-align: center;
}

.wavyline {
  border: none;
  height: 40px; /* Height of the wave */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 50 40" preserveAspectRatio="none"><path d="M0 20 Q 2.5 10, 5 20 T 10 20 Q 12.5 30, 15 20 T 20 20 Q 22.5 10, 25 20 T 30 20 Q 32.5 30, 35 20 T 40 20 Q 42.5 10, 45 20 T 50 20" fill="transparent" stroke="black" stroke-width="2"/></svg>')
    repeat-x;
}

/* Organisation.module.css */

.membersTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.membersTable th,
.membersTable td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
  text-align: center; /* Center-align text in all table cells */
}

.membersTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.unitCell {
  font-weight: bold;
  background-color: #f2f2f2;
}

.TableContainer {
  margin: 10px;
}
