/* Header2.module.css */

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  padding: 15px 25px;
  border: 2px solid rgb(2, 2, 42);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); /* Further increased elevation */
  margin: 1% auto 0; /* Centering the header horizontally */
  border-radius: 20px;
  background-color: #ffffff;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  width: 130px;
  height: auto;
  margin-right: 10px;
}

.names {
  margin-left: 10px;
  flex: 30%; /* Fixed width for names section */
  text-align: center;
}

.heading {
  margin: auto;
  color: red;
  font-weight: 900;
  font-size: 35px;
}

.subheading {
  margin: auto;
  color: blue;
  font-weight: 700;
}

.right {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.text1 {
  font-size: 16px;
  color: blue;
  margin-bottom: 5px;
  font-weight: 400;
}

.text2 {
  font-size: 16px;
  color: blue;
  margin-bottom: 25px;
  margin-top: 10px;
  font-weight: 400;
}

.text3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 3%;
}

.icons {
  display: flex;
  justify-content: center; /* Center icons horizontally */
}

.socialIcon {
  font-size: 1.3rem;
  cursor: pointer;
  color: #000;
  margin: 0 5px; /* Add margin to space out the icons */
}

.socialIcon:hover {
  color: #666;
}
/* Header.module.css */

.navbarContainer {
  background-color: navy;
  display: flex;
  color: #f5f6f7;
  justify-content: space-between;
  align-items: center; /* Vertically center items */
  padding: 10px 20px;
  margin: 1% auto 0; /* Centering the header horizontally */
  width: 93%;
  border-radius: 13px;
  overflow: visible; /* Allow dropdown to be visible outside the container */
}

.dropicon {
  margin-left: 5px;
  justify-self: center;
  align-self: center;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.menuItem {
  position: relative;
  margin: 0 10px;
}

.menuLink {
  text-decoration: none;
  color: #fff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.menuLink:hover,
.menuItem:hover > .menuLink {
  color: azure;
}

.menuDropdown {
  display: none;
  position: absolute;
  top: 110%; /* Adjust as needed to position below the navbar */
  left: 0;
  background-color: #fff;
  color: #000;
  list-style: none;
  padding: 8px 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000; /* Ensure dropdown is above other elements */
  width: 100%; /* Ensure dropdown takes the width of the menu item */
}

.menuItem:focus-within .menuDropdown {
  display: block;
}
.menuDropdown .submenuItem {
  padding: 0;
}

.submenuLink {
  display: block;
  padding: 8px 10px;
  text-decoration: none;
  color: #000;
  white-space: nowrap;
}

.submenuLink:hover {
  background-color: #ddd;
}
