/* ImpLinks.module.css */
.linksContainer {
  padding: 20px;
}

.columnsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.leftColumn,
.rightColumn {
  display: flex;
  flex-direction: column;
}

.fullWidth {
  margin-top: 20px;
}

.category {
  margin-bottom: 20px;
}

.linksList {
  list-style-type: none;
  padding: 0;
}

.linkItem {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.linkItem a {
  text-decoration: none;
  color: #6160fa;
  font-weight: bold;
}

.linkItem a:hover {
  text-decoration: underline;
}

.space {
  padding-left: 10px;
}

.tableContainer {
  margin-top: 20px;
}

.tableContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tableContainer td {
  padding: 6px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.tableContainer td a {
  text-decoration: none;
  color: #6160fa;
  font-weight: bold;
}

.tableContainer td a:hover {
  text-decoration: underline;
}
