.pdf-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns grid */
  gap: 20px; /* Adjust gap between items */
  padding: 30px 20px;
}

.pdf-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pdf-icon {
  height: 180px; /* Increase icon size */
  width: 120px; /* Increase icon size */
  margin-bottom: 10px; /* Spacing between icon and name */
}

.pdf-link {
  font-size: 14px; /* Adjust font size of PDF names */
  text-decoration: none;
  color: #333;
  width: 100%; /* Ensure name spans the width */
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
