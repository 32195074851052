/* Admin.module.css */

.adminContainer {
  text-align: center;
  margin: auto;
  max-width: 95%;
}

.form {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px auto; /* Center the form container */
}

.title {
  margin-bottom: 20px;
  color: #333;
}

.formGroup {
  display: flex;
  margin-bottom: 15px;
  align-items: center; /* Align items vertically */
}

.label {
  font-weight: bold;
  margin-right: 10px;
  width: 20%;
  display: flex;
  align-items: center;
}

.input,
.textarea,
.select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea {
  resize: vertical;
  height: 100px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 10px;
  padding: 10px 45px;
  background-color: rgb(11, 33, 138);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}
