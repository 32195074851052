body {
  background-color: #f9f9f0;
}

.headerContainer {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 10px 20px;
  border-bottom: 2px solid rgb(2, 2, 42);
  border-top: 0.3px solid rgb(2, 2, 42);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3); /* Further increased elevation */
  margin: 1% auto 0; /* Centering the header horizontally */
  border-radius: 20px;
  background-color: #f5f6f7;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  width: 120px;
  height: auto;
  cursor: pointer;
}

.center {
  text-align: center;
}

.heading {
  color: red;
}

.headingbox {
  margin-bottom: 1px;
}

.head {
  margin: 0;
}

.subhead {
  color: blue;
  font-weight: 500;
}

.right {
  display: flex;
  /* margin-right: 40px; */
}

.subHeader {
  background-color: navy;
  display: flex;
  color: #f5f6f7;
  justify-content: space-between;
  align-items: center; /* Vertically center items */
  padding: 10px 20px;
  margin: 1% auto 0; /* Centering the header horizontally */
  width: 95%;
  border-radius: 10px;
  overflow: hidden; /* Ensure slogan hides when moving out of the container */
}

.socialIcon {
  font-size: 1.2rem;
  cursor: pointer;
  color: #f5f6f7;
  margin: 0 5px; /* Add margin to space out the icons */
}

.socialIcon:hover {
  color: rgb(228, 218, 218);
}

.slogan {
  font-size: 1.1rem;
  font-weight: 700;
  animation: moveSlogan 10s linear infinite;
  white-space: nowrap; /* Prevent slogan from wrapping to next line */
}

@keyframes moveSlogan {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
/* App.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  background-color: #000; /* Ensure the background color is set */
}
