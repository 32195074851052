/* src/components/Homepage.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns */
  gap: 0px; /* Adjust the gap between items */
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.image {
  width: 100%;
  max-width: 130px;
  height: 180px;
  object-fit: cover;
  border: rgb(11, 33, 128) 1px solid;
  border-radius: 10px;
}

.name {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2px;
  line-height: 1;
}

.post {
  font-size: 1rem;
  /* margin-top: 5px; */
  line-height: 1.2;
}

.banner {
  width: 75%;
  height: auto;
  max-height: 500px;
  margin-top: 50px;
  object-fit: contain;
}

.carousel {
  width: 85%;
  margin: 15px auto;
  position: relative;
  padding: 30px 50px;
}

.carouselImage {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.prevBtn,
.nextBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 26px;
  color: #000;
  cursor: pointer;
}

.prevBtn {
  left: 0;
}

.nextBtn {
  right: 0;
}

.knowBoothBtn {
  margin-top: 80px;
  margin-bottom: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  animation: pulse 1.5s infinite;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5);
  transition: transform 0.2s, background-color 0.3s;
}

.knowBoothBtn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0px 6px 15px rgba(0, 123, 255, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5);
  }
}
