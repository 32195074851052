/* Footer.module.css */

.footer {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  bottom: 0;
}

.left {
  flex: 1;
}

.center {
  flex: 2;
  text-align: center;
  font-size: medium;
  font-weight: 600;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.socialIcon {
  font-size: 1rem;
  margin-left: 15px;
  color: white;
  cursor: pointer;
}

.socialIcon:hover {
  color: rgb(228, 218, 218);
}
